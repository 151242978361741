import serviceLists from './serviceLists';
import styles from 'templates/terms-of-service/termsOfService.module.scss';
import Container from 'components/Container/Container';
import Layout from 'components/Layout/Layout';
import React, { Fragment } from 'react';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import Content from '@moonshineragency/ui/src/components/Content/Content';
import { Link } from '@moonshineragency/ui/src/components/Link/Link';

const Service = () => {
  const encodedEmail = (
    <Link
      target="_blank"
      variant="noUnderline"
      href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#99;&#97;&#114;&#111;&#108;&#121;&#110;&#64;&#115;&#99;&#111;&#112;&#101;&#45;&#115;&#99;&#105;&#46;&#111;&#114;&#103;"
    >
      &#67;&#97;&#114;&#111;&#108;&#121;&#110;&#64;&#115;&#99;&#111;&#112;&#101;&#45;&#115;&#99;&#105;&#46;&#111;&#114;&#103;{' '}
    </Link>
  );

  return (
    <Layout
      title="Terms of Service, spinal cord injury trials"
      description="SCI Trials Finder is a service provided without cost to users to deliver current information about clinical SCI trials."
      onlyShowSearchIcon
      isContentpage
    >
      <Container size="lg">
        <div className={styles.serviceContainer}>
          <Heading size="h1" theme="primary">
            Terms of service
          </Heading>
          <div className={styles.headingText}>
            <Content size="copy75" variant="bold">
              Last updated 01 September 2021
            </Content>
            <Content
              size="copy100"
              variant="light"
              className={styles.mainSection}
            >
              Please read this User Agreement before using this Service. If you
              do not agree with all of these Terms of Service, then you are
              prohibited from using the Site and Services and you must
              discontinue use immediately.
            </Content>
          </div>
          {serviceLists.map(list => {
            return (
              <Fragment key={list.title}>
                <div>
                  <div id={list.linkId} />
                  <Heading
                    theme="primary"
                    className={styles.serviceListHeading}
                    size="h3"
                  >
                    {list.title}
                  </Heading>
                  {list.textArray &&
                    list.textArray.map(el => {
                      return (
                        <div key={el.id}>
                          <Content
                            size="copy100"
                            variant="light"
                            className={styles.mainSection}
                            withParser
                          >
                            {el.section}
                          </Content>
                        </div>
                      );
                    })}
                </div>
              </Fragment>
            );
          })}
          <Content size="copy100" variant="bold">
            By continuing to use this service, you agree to abide by the terms
            of this user agreement.
          </Content>
          <div className={styles.contactWrapper}>
            <Heading size="h1" theme="primary">
              Contact information
            </Heading>
            <Content size="copy100" variant="light">
              @ASIA: Carolyn Moffatt <br />
              9702 Gayton Rd, Suite 306, Richmond, VA 23238 <br />
              Phone: 877-274-2724 <br />
              <span className={styles.email}>{encodedEmail}</span>
            </Content>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Service;
